.footer-style{

  width:100vw;
}
.footer-end{
float:right
}
@media only screen and (max-height: 700px) {
  .footer-style{
      margin-top: 15vh;
      width:100vw;
  }

  
}
@media only screen and (max-width: 765px) {
.footer-end{
  float:left;
  margin-top: 10px;
}
}
.ant-modal {
  border-radius: 20px;
}
.ant-modal-header  {
  background-color: #0a2741;
  text-align: center;

}
.ant-modal-title {
  color: #fff;
  font-size: 1.5rem;
}
.ant-modal-content {
  border-radius: 15px;
  background-color: #0a2741;
}
.ant-modal-body {
  background-color: #fff;
}
.ant-modal-footer {
  background-color: #fff;

}
.ant-modal-close-x {
  color: #fff;
}
