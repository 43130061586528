.sign-cont {
  margin-top: 100px;
}
.img-text {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 80%;
}
@media only screen and (max-width: 768px) {
  .sign-cont {
    margin-top: 0px;
  }
}
