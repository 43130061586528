.back-image {
  height: 100vh;
  align-content: center;
  background-size: cover;

  background-image: url("./../../asset/images/background.png");

}
.back-style{

position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
overflow-x: hidden;
line-height: 0;


}
#video1{
  position: fixed;
  right: 0;
  bottom: 0;
object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  align-content: center;
  background-size: cover;
}
.leaflet-container {
  width: 100%;
  min-height: 100%;
}
.leaflet-control {
  display: none;
}
@media only screen and (max-height: 700px) {

  .back-image {
    height:130vh;
    align-content: center;
    background-size: cover;
    background-image: url("./../../asset/images/background.png");
  }
  .back-style{
    background-color:rgba(0,0,0,0.3);
    height:130vh;
    }
}