.indiv-sign-cont-bg {
  background-color: rgba(0, 0, 0,0);
}

.indiv-sign-cont {
  max-width: 400px;
}
.indiv-sign-cont .ant-input-password {
  min-height: 40px;
}
.indiv-sign-cont .t .ant-input {
  min-height: 40px;
}
.indiv-sign-cont .ant-btn {
  color: #fff;
  border: none;
}
#codepass{
  background-color:rgba(0, 0, 0,0);
  color:#563ead
}
#coderepass{
  background-color:rgba(0, 0, 0,0);
  color:#563ead
}
.activebtn {
  border-bottom: 2px solid white;
}
.cursor {
  cursor: pointer;
  font-size: 18px;
}
.forgot-text {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
 
}
@media only screen and (max-width: 768px) {
  .forgot-text {
    font-size: 16px;
  }
}