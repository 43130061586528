.font-color {
  color: white;
}
.pointer {
  cursor: pointer;
}
.header-title {
  font-weight: 600;
  font-size: 2rem;
}
.sign-in-title {
  font-size: 2rem;
  padding: 1px 15px;
  border-radius: 10px;
}
.sign-in-title:hover {
  background-color: #0a2741;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .header-title {
    font-weight: 600;
    font-size: 25px;
  }
  .sign-in-title {
    font-size: 1rem;
    padding: 1px 5px;
    border-radius: 10px;
  }
}
