.indiv-sign-cont-bg {
  background-color: #e1e2e4;
}

.indiv-sign-cont  {
  max-width: 400px;
}
.indiv-sign-cont .ant-input-password {
  min-height: 40px;

}
.indiv-sign-cont .t .ant-input {
  min-height: 40px;
  background-color:  white;
  color:#fff;
  font-size: 17px;
}

.ant-input-password  {
  background-color:white;
  color:#fff;
 
  
}
#signpass  {
  background-color:rgba(0, 0, 0,0);
  color:#fff
}
#signpass.ant-input{
  margin-top: -3px;
  color:#563ead
}
#regpass{
  background-color:rgba(0, 0, 0,0);
  color:#563ead
}
#regrepass{
  background-color:rgba(0, 0, 0,0);
  color:#563ead
}

.ant-input-password-icon{
  color:#fff
}
.indiv-sign-cont .ant-btn {
  color: #fff;
  border: none;
}
.activebtn {
  border-bottom: 2px solid white;
}
.cursor {
  cursor: pointer;
  font-size: 18px;
}
.forgot-text {
  cursor: pointer;
  font-size: 18px;
  color:lavender;
}
.forgot-password-1{
  max-width:150px;
  font-size:19px;
  margin-right: 20px;
  float:right;
  font-style: italic;
  margin-top: -55px;
  }
.inputs{
  background-color:white;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #563ead;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,0) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.ant-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
.icon-style{
 font-size:25px;
 color:white
}
.input-style{
  padding-left: 15px;
  padding-top: 5%;
  width:500px;
  height:60px;
  font-size: 19px;
  margin-left: -45px;

}
.indiv-sign-cont .t .ant-input {
  color:#563ead
}
.ant-input-password-icon {
  color:black
}
.icon-div{
  position:absolute;
  color:black;
  margin-top:-90px;
  margin-left:-30px;
  z-index: 3;
}
.form1-style{
  height:70px
}
.ant-form-item-with-help {
  margin-bottom: 23px;
}
.ant-input{
  font-size: 20px;
}
.button2-style{


font-size: 19px;
width:200px;
height:50px;
background-color: #563ead;
margin-left: -45px;
margin-top: 5vh;



}
.button3-style{


  font-size: 19px;
  width:200px;
  height:50px;
  background-color: white;
  margin-left: 20px;
  margin-top: 5vh;

  }
  .button4-style{


    font-size: 19px;
    width:200px;
    height:50px;
    background-color: #563ead;
    margin-top: 5vh;
  
    }
  .check-stl{

  }

.login-name{
color:white;
font-size: 40px;

}
.login-content{
  color:white;
  font-size: 20px;
}
.notmember{
  margin-top:60px;
  color:#fff;
  font-style: italic;
}
@media only screen and (max-width: 768px) {
  .forgot-text {
    font-size: 16px;
  }
  .login-name{
    color:white;
    font-size: 35px;
    
    }

}
@media only screen and (max-width: 555px) {
  .icon-style{
    font-size:25px;
    color:white
   }
   .input-style{
     padding-left:15px;
     width:350px;
     height:60px;
     font-size: 18px;
     margin-left: -15px;
   
   }
   .icon-div{
     position:absolute;
     margin-top:-85px;
     margin-left:35px
   }
   .form1-style{
     height:70px
   }
   .ant-input{
    font-size: 18px;
  }
.button2-style{


font-size: 19px;
width:100px;
height:40px;
background-color: #563ead;
margin-top: 5vh;
height:45px;
margin-left: 10px;


}
.button3-style{


  font-size: 19px;
  width:100px;
  height:40px;
  background-color: white;
  margin-left: 20px;
  margin-top: 5vh;
  height:45px;
  }
    .login-name{
      color:white;
      font-size: 30px;
      
      }
      .login-content{
        color:white;
        font-size: 18px;
      }

}
@media only screen and (max-width: 407px) {
  .login-content{
    color:white;
    font-size: 16px;
  }
  .icon-style{
    font-size:20px;
    color:white;
   }
   .input-style{
     padding-left: 15;
     width:250px;
     height:50px;
     font-size: 16px;
     margin-left: -15px;
   
   }
   .icon-div{
     position:absolute;
     margin-top:-65px;
     margin-left:20vw
   }
   .form1-style{
     height:50px
   }
   .ant-input{
    font-size: 16px;
  }
  .button2-style{


    font-size: 19px;
    width:100px;
    height:40px;
    background-color: #563ead;
    margin-top: 5vh;
    height:45px;
    margin-left: 60px;
    
    
    }
    .button3-style{
    
    
      font-size: 19px;
      width:100px;
      height:40px;
      background-color: white;
      margin-left: 20px;
      margin-top: 5vh;
      height:45px;
      }
    .ant-input{
      margin-top: 7px;
    }
    .forgot-password-1{
      max-width:250px;
      font-size:19px;
      padding-left: 30px;

      }
      .login-name{
        color:white;
        font-size: 25px;
        
        }

}
@media only screen and (max-width: 377px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:65px
  }
}
@media only screen and (max-width: 359px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:55px
  }
}
@media only screen and (max-width: 341px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:45px
  }
}
@media only screen and (max-width: 323px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:35px
  }
}
@media only screen and (max-width: 307px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:25px
  }
}
@media only screen and (max-width: 291px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:15px
  }
}
