@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "IBM Plex Sans", sans-serif;
}
* {
  outline: none;
}
.ant-form-item-has-error :not(.ant-input-disabled).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
background-color: white;
}
.font-color {
  color: white;
}
.pointer {
  cursor: pointer;
}
.header-title {
  font-weight: 600;
  font-size: 2rem;
}
.sign-in-title {
  font-size: 2rem;
  padding: 1px 15px;
  border-radius: 10px;
}
.sign-in-title:hover {
  background-color: #0a2741;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .header-title {
    font-weight: 600;
    font-size: 25px;
  }
  .sign-in-title {
    font-size: 1rem;
    padding: 1px 5px;
    border-radius: 10px;
  }
}

.sign-cont {
  margin-top: 100px;
}
.img-text {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 80%;
}
@media only screen and (max-width: 768px) {
  .sign-cont {
    margin-top: 0px;
  }
}

.indiv-sign-cont-bg {
  background-color: #e1e2e4;
}

.indiv-sign-cont  {
  max-width: 400px;
}
.indiv-sign-cont .ant-input-password {
  min-height: 40px;

}
.indiv-sign-cont .t .ant-input {
  min-height: 40px;
  background-color:  white;
  color:#fff;
  font-size: 17px;
}

.ant-input-password  {
  background-color:white;
  color:#fff;
 
  
}
#signpass  {
  background-color:rgba(0, 0, 0,0);
  color:#fff
}
#signpass.ant-input{
  margin-top: -3px;
  color:#563ead
}
#regpass{
  background-color:rgba(0, 0, 0,0);
  color:#563ead
}
#regrepass{
  background-color:rgba(0, 0, 0,0);
  color:#563ead
}

.ant-input-password-icon{
  color:#fff
}
.indiv-sign-cont .ant-btn {
  color: #fff;
  border: none;
}
.activebtn {
  border-bottom: 2px solid white;
}
.cursor {
  cursor: pointer;
  font-size: 18px;
}
.forgot-text {
  cursor: pointer;
  font-size: 18px;
  color:lavender;
}
.forgot-password-1{
  max-width:150px;
  font-size:19px;
  margin-right: 20px;
  float:right;
  font-style: italic;
  margin-top: -55px;
  }
.inputs{
  background-color:white;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #563ead;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,0) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.ant-input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.ant-input:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.ant-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
.icon-style{
 font-size:25px;
 color:white
}
.input-style{
  padding-left: 15px;
  padding-top: 5%;
  width:500px;
  height:60px;
  font-size: 19px;
  margin-left: -45px;

}
.indiv-sign-cont .t .ant-input {
  color:#563ead
}
.ant-input-password-icon {
  color:black
}
.icon-div{
  position:absolute;
  color:black;
  margin-top:-90px;
  margin-left:-30px;
  z-index: 3;
}
.form1-style{
  height:70px
}
.ant-form-item-with-help {
  margin-bottom: 23px;
}
.ant-input{
  font-size: 20px;
}
.button2-style{


font-size: 19px;
width:200px;
height:50px;
background-color: #563ead;
margin-left: -45px;
margin-top: 5vh;



}
.button3-style{


  font-size: 19px;
  width:200px;
  height:50px;
  background-color: white;
  margin-left: 20px;
  margin-top: 5vh;

  }
  .button4-style{


    font-size: 19px;
    width:200px;
    height:50px;
    background-color: #563ead;
    margin-top: 5vh;
  
    }
  .check-stl{

  }

.login-name{
color:white;
font-size: 40px;

}
.login-content{
  color:white;
  font-size: 20px;
}
.notmember{
  margin-top:60px;
  color:#fff;
  font-style: italic;
}
@media only screen and (max-width: 768px) {
  .forgot-text {
    font-size: 16px;
  }
  .login-name{
    color:white;
    font-size: 35px;
    
    }

}
@media only screen and (max-width: 555px) {
  .icon-style{
    font-size:25px;
    color:white
   }
   .input-style{
     padding-left:15px;
     width:350px;
     height:60px;
     font-size: 18px;
     margin-left: -15px;
   
   }
   .icon-div{
     position:absolute;
     margin-top:-85px;
     margin-left:35px
   }
   .form1-style{
     height:70px
   }
   .ant-input{
    font-size: 18px;
  }
.button2-style{


font-size: 19px;
width:100px;
height:40px;
background-color: #563ead;
margin-top: 5vh;
height:45px;
margin-left: 10px;


}
.button3-style{


  font-size: 19px;
  width:100px;
  height:40px;
  background-color: white;
  margin-left: 20px;
  margin-top: 5vh;
  height:45px;
  }
    .login-name{
      color:white;
      font-size: 30px;
      
      }
      .login-content{
        color:white;
        font-size: 18px;
      }

}
@media only screen and (max-width: 407px) {
  .login-content{
    color:white;
    font-size: 16px;
  }
  .icon-style{
    font-size:20px;
    color:white;
   }
   .input-style{
     padding-left: 15;
     width:250px;
     height:50px;
     font-size: 16px;
     margin-left: -15px;
   
   }
   .icon-div{
     position:absolute;
     margin-top:-65px;
     margin-left:20vw
   }
   .form1-style{
     height:50px
   }
   .ant-input{
    font-size: 16px;
  }
  .button2-style{


    font-size: 19px;
    width:100px;
    height:40px;
    background-color: #563ead;
    margin-top: 5vh;
    height:45px;
    margin-left: 60px;
    
    
    }
    .button3-style{
    
    
      font-size: 19px;
      width:100px;
      height:40px;
      background-color: white;
      margin-left: 20px;
      margin-top: 5vh;
      height:45px;
      }
    .ant-input{
      margin-top: 7px;
    }
    .forgot-password-1{
      max-width:250px;
      font-size:19px;
      padding-left: 30px;

      }
      .login-name{
        color:white;
        font-size: 25px;
        
        }

}
@media only screen and (max-width: 377px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:65px
  }
}
@media only screen and (max-width: 359px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:55px
  }
}
@media only screen and (max-width: 341px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:45px
  }
}
@media only screen and (max-width: 323px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:35px
  }
}
@media only screen and (max-width: 307px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:25px
  }
}
@media only screen and (max-width: 291px) {
  .icon-div{
    position:absolute;
    margin-top:-65px;
    margin-left:15px
  }
}

.indiv-sign-cont-bg {
  background-color: rgba(0, 0, 0,0);
}

.indiv-sign-cont {
  max-width: 400px;
}
.indiv-sign-cont .ant-input-password {
  min-height: 40px;
}
.indiv-sign-cont .t .ant-input {
  min-height: 40px;
}
.indiv-sign-cont .ant-btn {
  color: #fff;
  border: none;
}
#codepass{
  background-color:rgba(0, 0, 0,0);
  color:#563ead
}
#coderepass{
  background-color:rgba(0, 0, 0,0);
  color:#563ead
}
.activebtn {
  border-bottom: 2px solid white;
}
.cursor {
  cursor: pointer;
  font-size: 18px;
}
.forgot-text {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
 
}
@media only screen and (max-width: 768px) {
  .forgot-text {
    font-size: 16px;
  }
}
.footer-style{

  width:100vw;
}
.footer-end{
float:right
}
@media only screen and (max-height: 700px) {
  .footer-style{
      margin-top: 15vh;
      width:100vw;
  }

  
}
@media only screen and (max-width: 765px) {
.footer-end{
  float:left;
  margin-top: 10px;
}
}
.ant-modal {
  border-radius: 20px;
}
.ant-modal-header  {
  background-color: #0a2741;
  text-align: center;

}
.ant-modal-title {
  color: #fff;
  font-size: 1.5rem;
}
.ant-modal-content {
  border-radius: 15px;
  background-color: #0a2741;
}
.ant-modal-body {
  background-color: #fff;
}
.ant-modal-footer {
  background-color: #fff;

}
.ant-modal-close-x {
  color: #fff;
}

.modal-ad {
    height: 90%;
    width: 100%;
    padding: 5px;
   

}

.outer-layer{
    max-width: 90%;
}
.modal-ad-inside{
    height: 100vh;
    width: 100vw;

}
.modal-ad-in {
    height: 100vh;

    line-height: normal;

}
.events-style{
    font-size: 25px;
    font-weight: bold;
}

@media only screen and (max-width: 767px) {
    .modal-ad-in {
        height: 60vh;
        width: 90vw;
    
    }
    .modal-ad-inside{
        
        height: 200vh;
        width: 90vw;
    }
}

.aboutus-head{
    height:35px;
}
.signin-button .ant-btn-primary{
    color: #fff;
    margin-top: 10px;
    border-color: #8e4b6e;
    background: #8e4b6e;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.signin-button .ant-btn-primary :hover{
    color: #fff;
    border-color: #8e4b6e;
    background: #8e4b6e;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.body-abt{
    width:95vw;
    margin-left: 5vw;
    padding-right: 5vw;

}
.para-style{
    text-indent:40px;
    font-size:17px;
    text-align: justify;
    padding-right: 5vw;
}
.img-aboutus{
    width:50vw;
    height:100%;
    display:block
}
@media only screen and (max-width: 767px)  {
    .body-abt{
        width:95vw;
        margin-left: 5vw;
        padding-right: 5vw;
    }
    .para-style{
        text-indent:40px;
        font-size:17px;
    }
    .img-aboutus{
        width:50vw;
        height:100%;
        display:none
    }
}
.back-image {
  height: 100vh;
  align-content: center;
  background-size: cover;

  background-image: url(/static/media/background.7f2f3dcc.png);

}
.back-style{

position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
overflow-x: hidden;
line-height: 0;


}
#video1{
  position: fixed;
  right: 0;
  bottom: 0;
object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  align-content: center;
  background-size: cover;
}
.leaflet-container {
  width: 100%;
  min-height: 100%;
}
.leaflet-control {
  display: none;
}
@media only screen and (max-height: 700px) {

  .back-image {
    height:130vh;
    align-content: center;
    background-size: cover;
    background-image: url(/static/media/background.7f2f3dcc.png);
  }
  .back-style{
    background-color:rgba(0,0,0,0.3);
    height:130vh;
    }
}
