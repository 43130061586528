
.aboutus-head{
    height:35px;
}
.signin-button .ant-btn-primary{
    color: #fff;
    margin-top: 10px;
    border-color: #8e4b6e;
    background: #8e4b6e;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.signin-button .ant-btn-primary :hover{
    color: #fff;
    border-color: #8e4b6e;
    background: #8e4b6e;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.body-abt{
    width:95vw;
    margin-left: 5vw;
    padding-right: 5vw;

}
.para-style{
    text-indent:40px;
    font-size:17px;
    text-align: justify;
    padding-right: 5vw;
}
.img-aboutus{
    width:50vw;
    height:100%;
    display:block
}
@media only screen and (max-width: 767px)  {
    .body-abt{
        width:95vw;
        margin-left: 5vw;
        padding-right: 5vw;
    }
    .para-style{
        text-indent:40px;
        font-size:17px;
    }
    .img-aboutus{
        width:50vw;
        height:100%;
        display:none
    }
}