.modal-ad {
    height: 90%;
    width: 100%;
    padding: 5px;
   

}

.outer-layer{
    max-width: 90%;
}
.modal-ad-inside{
    height: 100vh;
    width: 100vw;

}
.modal-ad-in {
    height: 100vh;

    line-height: normal;

}
.events-style{
    font-size: 25px;
    font-weight: bold;
}

@media only screen and (max-width: 767px) {
    .modal-ad-in {
        height: 60vh;
        width: 90vw;
    
    }
    .modal-ad-inside{
        
        height: 200vh;
        width: 90vw;
    }
}